var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"arrestedVehicleTable"}},[_c('v-data-table',{ref:"atable",staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.lists,"options":_vm.options,"footer-props":_vm.footer},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.created_at) .add(543, 'year') .format('D MMMYYYY เวลา HH:mm'))+" ")]}},{key:"item.fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.fullname || 'ไม่ระบุ')+" ")]}},{key:"item.car_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.commafy(item.car_total) || ''))])]}},{key:"item.motorcycle_total",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v(_vm._s(_vm.commafy(item.motorcycle_total) || ''))])]}},{key:"item.car_arrested",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"red","font-weight":"600"}},[_vm._v(_vm._s(_vm.commafy(item.car_arrested) || ''))])]}},{key:"item.motorcycle__arrested",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"color":"red","font-weight":"600"}},[_vm._v(_vm._s(_vm.commafy(item.motorcycle__arrested) || ''))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-speed-dial',{attrs:{"direction":"left","open-on-hover":""}},[_c('v-btn',{attrs:{"slot":"activator","color":"blue darken-4","icon":"","small":"","dark":"","fab":""},slot:"activator"},[_c('v-icon',[_vm._v("mdi-menu")])],1),_c('v-btn',{attrs:{"x-small":"","fab":"","color":"blue","dark":""},on:{"click":function($event){$event.stopPropagation();return _vm.rowClick(item)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-eye")])],1)],1)]}}],null,true)}),_c('div',{ref:"pp",staticClass:"d-none",attrs:{"id":"print"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }