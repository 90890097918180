import axios from 'axios'
import store from '@/store'
import router from '@/router'

let axiosInstance = axios.create({
  baseURL: 'http://tpccapi.test',
  timeout: 20000,
})

// ทำก่อนส่ง request
axiosInstance.interceptors.request.use(
  config => {
    config.headers['Content-Type'] = 'multipart/form-data'
    config.headers['Accept'] = 'application/json'

    // ตั้ง Authorization
    if (store.getters['Auth/isLoggedIn']) {
      const token = store.getters['Auth/accessToken']
      config.headers['Authorization'] = `Bearer ${token}`
    }

    return config
  },
  error => {
    // Do something with request error
    return Promise.reject(error)
  }
)

// แทรกระหว่างส่ง response กลับไปให้ component
axiosInstance.interceptors.response.use(
  function(response) {
    // ตรวจสอบว่ามี response and return to component
    return response.data
  },
  function(error) {
    if (error.response === undefined) return Promise.reject(error)
    if (error.response.status === 401) {
      store.commit('Auth/remove_token')
      router.replace({ name: 'Login' })
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

export default axiosInstance
