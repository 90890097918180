<template>
  <v-container fluid>
    <v-card :loading="loading">
      <v-toolbar color="indigo darken-3" dark dense flat>
        <v-toolbar-title>
          <v-icon>
            mdi-file-chart
          </v-icon>
          รายงานจำนวนรถที่พบการกระทำความผิด
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <!-- <v-select
          :items="types"
          label="ค้นหาจาก"
          style="width:40px; !important"
          v-model="type"
          hide-details
          single-line
          dense
          solo-inverted
        ></v-select> -->
        <!-- <v-text-field
          prepend-inner-icon="mdi--file-chart"
          width="200px"
          single-line
          hide-details
          dense
          solo-inverted
          label="กรอกคำค้นหา"
          clearable
          v-model="keyword"
          @keypress.enter="getList"
        ></v-text-field> -->
      </v-toolbar>
      <v-card-text>
        <div class="d-flex">
          <v-btn color="indigo darken-2" @click="exportClick" class="white--text">
            <v-icon small>
              mdi-file-excel
            </v-icon>
             Excel</v-btn
          >
          
        </div>
      </v-card-text>
      <arrested-vehicle-table
        :keyword="keyword"
        :type="type"
        ref="otable"
        @on-list-updated="updateJson"
      ></arrested-vehicle-table>
    </v-card>

  </v-container>
</template>

<script>
import arrestedVehicleTable from './components/arrestedVehicleTable'
import { exportExcel, exportExcelBureau } from '@/api/arrestedvehiclereport'
import { mapGetters } from 'vuex'

export default {
  methods: {
    getList() {
      // this.$refs.otable.getListFeedback()
    },
    exportClick() {
      this.loading = true
      if (this.bureauId) {
        const params = {
          start: this.dateStart,
          end: this.dateEnd,
          bureau: this.bureauId,
        }
        exportExcelBureau(params)
        .then(response => {
          this.loading = false

        })
      } else {
        const params = {
          start: this.dateStart,
          end: this.dateEnd,
        }
        exportExcel(params)
        .then(response => {
          this.loading = false

        })
      }
      
    },
    bChanged(v) {
      this.setBureau(v)
      // this.setDivision(null)
      // this.setDivisions([])
      // this.setStation(null)
      // this.setStations([])
      if (this.bureauId == null || this.bureauId == '') return
      // this.$store.dispatch('Appfilter/getDivision')
    },
    dChanged(v) {
      // this.setDivision(v)
      // this.setStation(null)
      // this.setStations([])
      if (this.divisionId == null || this.divisionId == '') return
      // this.$store.dispatch('Appfilter/getStation')
    },
    sChanged(v) {
      // this.setStation(v)
    },
    test() {},
    updateJson(json) {
      this.json = json
    },
  },
  data() {
    return {
      keyword: '',
      logDialog: false,
      userDialog: false,
      type: 'detail',
      types: [{ text: 'ข้อความรายงาน', value: 'detail' }],
      modify: 'add',
      loading: false,
      show1: false,
      show2: false,
      json: null,
    }
  },
  watch: {},
  components: {
    arrestedVehicleTable,
  },
  computed: {
    ...mapGetters('Appfilter', [
      'bureauId',
      'dateStart',
      'dateEnd',
    ]),
  },
}
</script>

<style></style>
